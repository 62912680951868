<template>
  <div class="bonusOrderList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      exportMethod="new"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="兑换商品" v-model="searchParams.productName"></v-input>
        <v-input label="订单编号" v-model="searchParams.orderCode"></v-input>
        <v-input label="业主姓名" v-model="searchParams.userName"></v-input>
        <v-select label="订单状态" v-model="searchParams.orderStatus" :options="availableOps"></v-select>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="orderDeal" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import {
  availableOps,
  availableMap
} from './map'
import { tenantParams } from 'common/select2Params'

export default {
  name: 'BonusOrderList',
  data () {
    return {
      availableOps,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      tenantParams,
      searchParams: {
        productName: '',
        orderCode: '',
        userName: '',
        orderStatus: undefined,
        regionId: ''
      },
      headers: [
        {
          prop: 'orderCode',
          label: '订单编号'
        },
        {
          prop: 'userName',
          label: '业主姓名'
        },
        {
          prop: 'userPhone',
          label: '联系电话'
        },
        {
          prop: 'address',
          label: '业主地址'
        },
        {
          prop: 'productName',
          label: '兑换商品'
        },
        {
          prop: 'orderStatusText',
          label: '状态',
          formatter (row) {
            return availableMap[row.orderStatus]
          }
        },
        {
          prop: 'intime',
          label: '下单时间'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        }
      ]
    }
  },
  methods: {
    edit (row) {
      this.$router.push({
        name: 'integralOrderForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
