import { generateMapByOpts } from 'common/utils'

// 订单状态
const availableOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '未接收',
    value: 1
  }, {
    text: '已接收',
    value: 2
  }, {
    text: '已处理',
    value: 3
  }
]

const availableMap = generateMapByOpts(availableOps)

export {
  availableOps,
  availableMap
}
